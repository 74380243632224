import React from 'react'
import { graphql } from 'gatsby'
import * as utils from '../common/utils'

// Components
import Layout from '../layouts/layout'
import About from '../components/About';

class about extends React.Component {
    constructor() {
        super();
        this.state = {
            windowWidth: 0,
            isMobile: false,
            isHome: true,
            currentPage: null,
        }
    }

    componentDidMount() {
        let width, timeoutId;
        this.init();

        window.addEventListener('resize', () => {
            clearTimeout(timeoutId);

            timeoutId = setTimeout(() => {
                width = utils.getWindowSize();
                this.setState({
                    windowWidth: width,
                })
            }, 500);
        });
    }

    init() {
        const { data } = this.props;
        const width = utils.getWindowSize();
        const ua = utils.getUserAgent();
        const pages = data.site.siteMetadata.pages;
        const pathname = utils.getPathname();
        const currentPage = utils.getItem(pages, pathname, 'pathname')[0];
        const isMobile = ua.indexOf('iPhone') > -1 || 
                        ua.indexOf('Android') > -1;
        const isHome = currentPage.isHome;

        this.setState({
            windowWidth: width,
            isMobile: isMobile,
            isHome: isHome,
            currentPage: currentPage,
        });
    }

    render() {
        const { data } = this.props;
        if (!this.state.currentPage) return null;

        return (
            <Layout 
                data={data} 
                isHome={this.state.isHome}
                page={this.state.currentPage}
            >
                <About />
            </Layout>
        );
    }
}

export default about;

export const query = graphql`
    query about {
        site {
            siteMetadata {
                title
                description
                keywords
                pages {
                    name
                    pathname
                    isHome
                }
            }
        }
    }
`